<template>
  <se-button
    size="medium"
    class="w-full overflow-hidden"
    :disabled="!enabled"
    :icon-only="!isExpanded"
    :class="[enabled ? scheme[props.colorScheme].bg : '', !isExpanded ? 'shrink-0' : '']"
    @click.prevent="emits('optionClicked')"
  >
    <slot name="icon" />
    <div v-if="isExpanded" class="flex items-center gap-1 ml-6">
      <slot name="chatName"/>
      <span>•</span>
      <s v-if="advisor.discount" class="font-normal text-b-s">${{ formatPrice(+props.price) }}</s>
      <span class="font-semibold text-h-s">${{ formatPrice(discountedPrice) }}</span>
    </div>
  </se-button>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AdvisorService } from '@/src/modules/advisor/services/advisor.service';
import SeButton from '@/src/shared/ui-kit/button/Button';

const emits = defineEmits<{
  optionClicked: any
}>();

const props = defineProps<{
  advisor: any,
  colorScheme: string;
  disabled: boolean;
  price: number;
  isExpanded: boolean;
}>();

const scheme = {
  primary: {
    bg: '!bg-primary-100',
    text: 'text-primary-100',
  },
  feature: {
    bg: '!bg-feature-100',
    text: 'text-feature-100',
  },
  love: {
    bg: '!bg-love-100',
    text: 'text-love-100',
  },
};

const isOnline = computed(() => props.advisor.status === 'online');

const enabled = computed(() => isOnline.value && !props.disabled);

const discountedPrice = computed(() => AdvisorService.getDiscountedPrice(props.price, props.advisor));

const formatPrice = (price: number): string => price.toFixed(2);
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorChatCardOption',
};
</script>
