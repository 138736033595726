<template>
  <div v-if="advisor" class="flex w-full">
    <se-advisor-chat-card-option
        :advisor="advisor"
        :is-expanded="expandedOption === 'text'"
        :disabled="!advisor.text_enabled"
        :price="parseFloat(advisor.per_minute_fee_text, 10)"
        color-scheme="primary"
        @option-clicked="() => optionClicked('text')"
    >
      <template #icon>
        <se-icon name="message-circle" :size="20"/>
      </template>
      <template #chatName>
        Chat
      </template>
    </se-advisor-chat-card-option>

    <se-advisor-chat-card-option
        :advisor="advisor"
        :is-expanded="expandedOption === 'audio'"
        :disabled="!advisor.audio_enabled"
        :price="parseFloat(advisor.per_minute_fee_audio, 10)"
        color-scheme="feature"
        @option-clicked="() => optionClicked('audio')"
    >
      <template #icon>
        <se-icon name="phone" :size="20" />
      </template>
      <template #chatName>
        Call
      </template>
    </se-advisor-chat-card-option>

    <se-advisor-chat-card-option
        :advisor="advisor"
        :is-expanded="expandedOption === 'video'"
        :disabled="!advisor.video_enabled"
        :price="parseFloat(advisor.per_minute_fee_video, 10)"
        color-scheme="love"
        @option-clicked="() => optionClicked('video')"
    >
      <template #icon>
        <se-icon name="video" :size="20" />
      </template>
      <template #chatName>
        Video
      </template>
    </se-advisor-chat-card-option>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import { chatStore } from '@/src/store/chat';
import { usersStore } from '@/src/store/users';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeAdvisorChatCardOption from "@/src/modules/advisor/components/shared/advisor-chat-card-option.vue";
import {computed, ref} from "vue";

const props = defineProps<{
  advisor: any
}>();

const { $toast } = getGlobalProperties();

const users = usersStore();
const { isAdvisor } = storeToRefs(users);

const { forceStartChat } = chatStore();

const chatOption = ref('');

const expandedOption = computed(() => {
  if (chatOption.value) return chatOption.value;
  if (props.advisor.text_enabled) return'text'
  else if (props.advisor.audio_enabled) return'audio'
  else if (props.advisor.video_enabled) return'video'
})

const optionClicked = (option: string) => {
  let isOptionEnabledName = option + '_enabled'
  if (!props.advisor[isOptionEnabledName]) return
  if (expandedOption.value === option) {
    startChat(option)
  } else {
    chatOption.value = option
  }
}

const startChat = (chatMode: string) => {
  if (isAdvisor.value) {
    $toast({
      message: 'Unable to chat with another advisor',
      type: 'error',
    });
  } else {
    forceStartChat(props.advisor, chatMode);
  }
};
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCardChatOptions',
};
</script>
